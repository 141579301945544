.layout-button-is-animated {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
    transform: scale(1) translate(0,0);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05) translate(0, -10%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0,0);
  }
}
