@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Arquitecta';
    src: url('./assets/fonts/Arquitecta/Arquitecta.woff2') format("woff2");

}

@font-face {
    font-family: 'Arquitecta';
    font-weight: 500;
    src: url('./assets/fonts/Arquitecta/arquitecta-medium.woff2') format("woff2");
}

@font-face {
    font-family: 'Arquitecta';
    font-weight: bold;
    src: url('./assets/fonts/Arquitecta/ArquitectaBlack.woff2') format("woff2");
}

@font-face {
    font-family: 'Arquitecta';
    font-weight: lighter;
    src: url('./assets/fonts/Arquitecta/ArquitectaLight.woff2') format("woff2");
}

body {
    font-family: 'Arquitecta' !important;
}

.Toastify__toast {
    font-family: 'Arquitecta' !important;
}

.swiper-pagination-bullet-active {
    /* background-color: #000 !important; */
}


:root {
    /* --nextui-primarys: hsl(263, 67%, 39%) !important; */
    --nextui-primary: 263 67% 39%;
    /* --swiper-navigation-color: #000 !important; */
    --swiper-theme-color: #ffb700 !important;
}